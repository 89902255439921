"use client";

import { useEffect, useState } from "react";
import { useParams } from "next/navigation";
import { useTheme } from "next-themes";
import { useTranslations } from "next-intl";
import { sendGTMEvent } from "@next/third-parties/google";

export default function NewsletterForm() {
  const { locale } = useParams();
  const { theme, systemTheme } = useTheme();
  const t = useTranslations();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const isSubscribed = document.cookie
      .split("; ")
      .find((row) => row.startsWith("newsletterSubscribed="));
    if (isSubscribed) {
      setIsSubscribed(true);
    }
  }, []);

  const handleAnalyticsEvent = () => {
      sendGTMEvent({
        event: 'newsletter_subscription',
        event_category: 'newsletter',
        event_action: 'subscribe',
        value: 1,
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setErrorMessage(t("newsletter.invalidEmail"));
      return;
    }

    try {
      const userTheme = theme === "system" ? systemTheme : theme;

      const response = await fetch("/api/newsletter/subscribe", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name,
          email,
          theme: userTheme,
          locale: locale || "en",
        }),
      });

      if (response.ok) {
        setSuccessMessage(t("newsletter.success"));
        setName("");
        setEmail("");
        setIsSubscribed(true);
        handleAnalyticsEvent()
        document.cookie = "newsletterSubscribed=true; path=/; max-age=31536000"; // Expira en 1 año
      } else {
        const { error } = await response.json();
        if (response.status === 409) {
          setErrorMessage(t("newsletter.alreadySubscribed"));
        } else {
          setErrorMessage(error || t("newsletter.error"));
        }
      }
    } catch (err) {
      setErrorMessage(t("newsletter.error"));
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  if (isSubscribed) {
    return (
      <div className="text-center">
        <h5>{t("newsletter.thankYouMessage")}</h5>
        <p>{t("newsletter.alreadySubscribed")}</p>
      </div>
    );
  }

  return (
    <>
      <h5 className="text-center mb-2">{t("newsletter.description")}</h5>
      <form id="newsletter" className="mx-auto" onSubmit={handleSubmit}>
        {successMessage && <div className="alert alert-success">{successMessage}</div>}
        <div className="mb-1">
          <input
            type="text"
            className="form-control form-control-sm my-1"
            id="name"
            placeholder={t("newsletter.nameField")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            className="form-control form-control-sm my-1"
            id="email"
            placeholder={t("newsletter.emailField")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary-outline btn-sm my-2 w-100">
          {t("newsletter.subscribeButton")}
        </button>
        {errorMessage && <small>{errorMessage}</small>}
      </form>
    </>
  );
}
