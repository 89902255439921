"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";
import { format } from "date-fns";
import { EventSourcePolyfill } from "event-source-polyfill";

import "../_fixtures.scss";

import { FavoriteLeague } from "../../LeaguesOfTheMoment/LeaguesOfTheMoment";

const LiveFixtures = ({
  favoriteLeagues,
}: {
  favoriteLeagues: FavoriteLeague[];
}) => {
  const [fixturesByLeague, setFixturesByLeague] = useState([]);
  const [error, setError] = useState<string | null>(null);
  const [expandedLeagues, setExpandedLeagues] = useState<Set<number>>(
    new Set(favoriteLeagues.map(({ league }) => league))
  );

  useEffect(() => {
    const eventSource = new EventSourcePolyfill(
      `${process.env.NEXT_PUBLIC_FOOTBALLTRENDS_API_URL}/fixtures/live`,
      {
        headers: {
          "x-custom-header": "footballtrends-front",
        },
        heartbeatTimeout: 120000,
      }
    );

    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        setFixturesByLeague(data);
      } catch (err) {
        console.error("Error parsing event data:", err);
      }
    };

    eventSource.onerror = (err) => {
      console.error("SSE connection error:", err);
      setError("Failed to connect to the live fixtures stream.");
      eventSource.close(); // Cierra la conexión si ocurre un error
    };

    return () => {
      eventSource.close();
    };
  }, []);

  const toggleLeague = (leagueId: number) => {
    const newExpandedLeagues = new Set(expandedLeagues);
    if (newExpandedLeagues.has(leagueId)) {
      newExpandedLeagues.delete(leagueId);
    } else {
      newExpandedLeagues.add(leagueId);
    }
    setExpandedLeagues(newExpandedLeagues);
  };

  const getFixtureStatus = (fixture: any) => {
    const { short, elapsed, extra } = fixture.fixture.status;

    if (short === "HT") return <strong>HT</strong>;
    if (short === "P") return <strong>P</strong>;

    return (
      <p className="playing" style={{ color: "#ff0000f3", fontWeight: "bold" }}>
        {short === "ET" ? "ET " : null}
        {<span>{elapsed || 0}</span>}
        {extra && <span style={{ fontSize: "0.65rem" }}>+{extra}</span>}
        &apos;
      </p>
    );
  };

  // MANEJAR MEJOR EL ERROR IMPLEMENTAR UN ERROR DIVERTIDO
  if (error) return <div>Something happened. Try Refresing.</div>;
  if (fixturesByLeague.length < 1) return <div>Loading.....</div>;

  return (
    <>
      <h2 className="h5 m-2">Live Fixtures</h2>
      <div
        className="glass-segment h-100 overflow-y-auto mt-0"
        style={{ fontSize: "0.8rem", maxHeight: "100%" }}
      >
        {fixturesByLeague.length > 0 &&
          fixturesByLeague.map((league: any) => (
            <React.Fragment key={league.league.id}>
              <div
                className="d-flex align-items-center p-1"
                style={{
                  backgroundColor: "var(--text-color)",
                  cursor: "pointer",
                }}
                onClick={() => toggleLeague(league.league.id)}
              >
                <div className="col-4">
                  <Image
                    src={league.league.flag ?? league.league.logo}
                    alt={`${league.league.country} flag`}
                    width={20}
                    height={20}
                  />
                  <small
                    className="ps-1"
                    style={{ color: "var(--primary-color)" }}
                  >
                    {league.league.country}
                  </small>
                </div>
                <div className="col-7">
                  <Image
                    src={league.league.logo}
                    alt={`${league.league.name} logo`}
                    width={20}
                    height={20}
                  />
                  <small
                    className="ps-1"
                    style={{ color: "var(--primary-color)" }}
                  >
                    {league.league.name}
                  </small>
                </div>
                <div className="col-1 text-center">
                  <Image
                    className="d-none logo-light"
                    src={
                      expandedLeagues.has(league.league.id)
                        ? "/icons/arrow-up-dark.png"
                        : "/icons/arrow-down-dark.png"
                    }
                    alt="Toggle League Dark"
                    width={13}
                    height={13}
                    onClick={() => toggleLeague(league.league.id)}
                    style={{ cursor: "pointer" }}
                  />
                  <Image
                    className="d-none logo-dark"
                    src={
                      expandedLeagues.has(league.league.id)
                        ? "/icons/arrow-up-light.png"
                        : "/icons/arrow-down-light.png"
                    }
                    alt="Toggle League Light"
                    width={13}
                    height={13}
                    onClick={() => toggleLeague(league.league.id)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>

              {expandedLeagues.has(league.league.id) && (
                <div>
                  {league.fixtures.map((fixture: any) => (
                    <div
                      key={fixture.fixture.id}
                      className="d-flex align-items-center py-1"
                    >
                      <div className="col-1 d-flex flex-column align-items-center">
                        <Image
                          src={fixture.teams.home.logo}
                          alt={fixture.teams.home.name}
                          width={20}
                          height={20}
                          className="mb-2"
                        />
                        <Image
                          src={fixture.teams.away.logo}
                          alt={fixture.teams.away.name}
                          width={20}
                          height={20}
                        />
                      </div>
                      <div className="col-7 d-flex flex-column align-items-start ps-1">
                        <p className="mb-2">{fixture.teams.home.name}</p>
                        <p className="">{fixture.teams.away.name}</p>
                      </div>
                      <div className="col-1 d-flex flex-column align-items-center">
                        <small
                          className="mb-2"
                          style={{
                            color: "var(--secondary-text-color)",
                            filter: "opacity(0.7)",
                          }}
                        >
                          {fixture.score.halftime.home ?? 0}
                        </small>
                        <small
                          style={{
                            color: "var(--secondary-text-color)",
                            filter: "opacity(0.7)",
                          }}
                        >
                          {fixture.score.halftime.away ?? 0}
                        </small>
                      </div>
                      <div className="col-1 d-flex flex-column align-items-center">
                        <p
                          className="mb-2"
                          style={{ color: "var(--secondary-text-color)" }}
                        >
                          {fixture.goals.home ?? 0}
                        </p>
                        <p style={{ color: "var(--secondary-text-color)" }}>
                          {fixture.goals.away ?? 0}
                        </p>
                      </div>
                      <div className="col-2 d-flex flex-column align-items-center">
                        <small
                          className="mb-2"
                          style={{ color: "var(--secondary-text-color)" }}
                        >
                          {format(new Date(fixture.fixture.date), "HH:mm")}
                        </small>
                        {getFixtureStatus(fixture)}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
      </div>
    </>
  );
};

export default LiveFixtures;
