"use client";

import { useEffect } from "react";
import { useTheme } from "next-themes";
import { sendGTMEvent } from "@next/third-parties/google";

export default function ThemeAnalyticsTracker() {
  const { theme, systemTheme } = useTheme();

  useEffect(() => {
    const activeTheme = theme === "system" ? systemTheme : theme;

    if (activeTheme) {
      console.log("envio", activeTheme)
      sendGTMEvent({
        event: "theme_usage",
        event_category: "User Preferences",
        event_action: "Theme Used",
        theme: activeTheme
      });
    }
  }, [theme, systemTheme]);

  return null;
}
