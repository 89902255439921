'use client'
import Link from 'next/link';
import { useRef } from 'react';

import LanguageSwitch from '@/components/molecules/LanguageSwitch/LanguageSwitch';

import ToggleMode from '@/components/atoms/ThemeSwitch/ThemeSwitch';
import useClickOutside from '@/hooks/useClickOutside';
import { useTranslations } from 'next-intl';
import useWindowSize from '@/hooks/useWindowSize';


const NavBar = ({ toggleMenu }: { toggleMenu: () => void }) => {
  const mobileNavBarRef = useRef<HTMLDivElement>(null);

  useClickOutside(mobileNavBarRef, () => toggleMenu())
  const { width } = useWindowSize();
  const l = useTranslations('leagues');
  const t = useTranslations('trends');

  return (
    <nav className="navbar navbar-expand-md">
      <div ref={mobileNavBarRef} className="navbar">
        <ul className="navbar-nav">
          <li onClick={toggleMenu}>
            <Link href="/trends" className="nav-link">
              {t('trends')}
            </Link>
          </li>
          <li>
            <Link href="/league/0/results" className="nav-link" onClick={toggleMenu}>
              {l('leagueStats')}
            </Link>
          </li>
          <li className={ width > 767 ? 'my-auto' : 'py-2'}>
            <LanguageSwitch />
          </li>
          <li className='d-none d-md-block' onClick={toggleMenu}>
            <ToggleMode />
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;

