"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";

import LinksDropdown from "@/components/atoms/LinksDropdown/LinksDropdown";

import { League } from "@/types/league";

import "./_leaguesOfTheMoment.scss";

export interface FavoriteLeague {
  league: number;
  votes: number;
}

export default function LeaguesOfTheMoment({
  messages,
  favoriteLeagues,
  leaguesPlaying,
}: {
  messages: any;
  leaguesPlaying: League[];
  favoriteLeagues: FavoriteLeague[];
}) {
  const [favoriteLeaguesSorted, setFavoriteLeaguesSorted] = useState<any[]>([]);
  const l = messages.leagues;

  useEffect(() => {
    try {
      if (leaguesPlaying.length > 0 && favoriteLeagues.length > 0) {
        const sorted = favoriteLeagues
          .map((fav) => {
            const league = leaguesPlaying.find((l) => l.id === fav.league);
            return league ? { ...league, votes: fav.votes } : null;
          })
          .filter((league) => league !== null)
          .slice(0, 20);
        setFavoriteLeaguesSorted(sorted);
      }
    } catch (error) {
      console.error("Error fetching leagues:", error);
    }
  }, []);

  return (
    <div className="navbar">
      <div className="col-12">
        <h3 className="h5 m-2">{l.leaguesOfTheMoment}</h3>
        <ul className="favoriteLeagues-list flex-md-column glass-segment mt-0">
          {favoriteLeaguesSorted.length > 0 &&
            favoriteLeaguesSorted.map(
              (league) =>
                league && (
                  <li key={league.id}>
                    <LinksDropdown
                      leagueId={league.id}
                      label={
                        <>
                          <Image
                            src={league.countryFlag as string}
                            alt={league.name}
                            width={20}
                            height={12}
                            style={{ marginRight: "8px" }}
                          />
                          {league.name}
                        </>
                      }
                    />
                  </li>
                )
            )}
        </ul>
      </div>
    </div>
  );
}
